import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { Button, Typography } from 'amphitheatre';
import { StaticImage } from 'gatsby-plugin-image';

import { FaLinux } from 'react-icons/fa';
import { ImAppleinc, ImWindows } from 'react-icons/im';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import DownloadSelector from 'src/Components/DownloadSelector';
import SEO from 'src/Components/SEO';

import * as Hero from 'src/page-styles/hero.styled';

const { Header, Paragraph } = Typography;

interface Props {
  data: {
    pigi: {
      frontmatter: {
        linux: string;
        mac: string;
        win32: string;
        win64: string;
      };
    };
    other: {
      edges: Array<{
        node: {
          frontmatter: {
            title: string;
            description: string;
            link: string;
          };
        };
      }>;
    };
  };
}

const OtherVersions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  a button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Resources: React.FunctionComponent<Props> = ({ data }: Props) => {
  const [selected, setSelected] = useState(0);
  const { pigi, other } = data;
  const { frontmatter } = pigi;
  const { edges: resources } = other;

  const options = ['P.I.G.I. Download'];
  const downloads = [
    <React.Fragment key={0}>
      <Header level={3}>P.I.G.I. Download</Header>
      <Paragraph>
        Note: Due to security updates to macOS, P.I.G.I. is currently not
        optimised for newer versions of macOS. This is a known issue we are
        currently working on. In the meantime, feel free to access P.I.G.I. with
        a Windows or Linux OS for the best mission operations experience.
      </Paragraph>
      <OtherVersions>
        <a
          href={frontmatter.win32}
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>
            <ImWindows /> Windows 32-bit
          </Button>
        </a>
        <a
          href={frontmatter.win64}
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>
            <ImWindows /> Windows 64-bit
          </Button>
        </a>
        <a
          href={frontmatter.mac}
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>
            <ImAppleinc /> Mac OS
          </Button>
        </a>
        <a
          href={frontmatter.linux}
          style={{ textDecoration: 'none' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button>
            <FaLinux /> Linux
          </Button>
        </a>
      </OtherVersions>
    </React.Fragment>,
  ];

  resources.forEach(({ node }, index) => {
    const { frontmatter } = node;
    options.push(frontmatter.title);

    downloads.push(
      <React.Fragment key={index + 1}>
        <Header level={3} style={{ textTransform: 'uppercase' }}>
          {frontmatter.title}
        </Header>
        <Paragraph>{frontmatter.description}</Paragraph>
        <Paragraph>
          <a
            href={frontmatter.link}
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Link to {frontmatter.title}</Button>
          </a>
        </Paragraph>
      </React.Fragment>,
    );
  });

  function changeResource(index: number) {
    setSelected(index);
  }

  return (
    <>
      <SEO
        title="Resources | Saber Astronautics"
        description="Downloads from Saber Astronautics to get you started and bring your ideas to life."
        url="https://www.saberastro.com/downloads"
      />
      <Layout>
        <Hero.Wrapper className="resources" style={{ minHeight: '100vh' }}>
          <Hero.Cover />
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/resources.jpg"
            alt="Downloads nice image"
          />
          <Hero.Text>
            <Container style={{ padding: 0 }}>
              <Header>Downloads</Header>
              <Paragraph>
                Here are some open source tools and other support to make your
                job easier. The Constellation Maker and CAD importer tools are{' '}
                <b>free to use</b> and modify as long as you give street cred to
                the original authors. Feel free to send us your changes and we
                will update so everyone can benefit.
              </Paragraph>
              <Paragraph>
                Check back here frequently for more tools, goodies, videos and
                more that the Saber team will add.
              </Paragraph>
              <DownloadSelector
                options={options}
                selected={selected}
                onSelect={changeResource}
              />
              {downloads[selected]}
            </Container>
          </Hero.Text>
        </Hero.Wrapper>
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        pigi: markdownRemark(frontmatter: { type: { eq: "pigi_download" } }) {
          frontmatter {
            win32
            win64
            mac
            linux
          }
        }
        other: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "resources" } } }
        ) {
          edges {
            node {
              frontmatter {
                description
                title
                link
              }
            }
          }
        }
      }
    `}
    render={(data) => <Resources data={data} />}
  />
);
