import React from 'react';
import { Wrapper, Option } from './DownloadSelector.styled';

interface Props {
  options: string[];
  selected: number;
  onSelect: (index: number) => void;
}

/**
 * FunctionComponent used purely to allow for users to select between the download options on the resources page
 *
 * @param {string[]} [options] List of strings showing the options
 * @param {number} [selected] Index which dictates which option should be shown as selected
 * @param {(index: number) => void} [onSelect] Callback function to tell the parent which index was selected
 */
function DownloadSelector({ options, selected, onSelect }: Props): JSX.Element {
  const elms = options.map((option, index) => (
    <Option
      key={`option-${index}`}
      className={selected === index ? 'selected' : ''}
      onClick={selected === index ? undefined : () => onSelect(index)}
    >
      {option}
    </Option>
  ));
  return <Wrapper>{elms}</Wrapper>;
}

export default DownloadSelector;
