import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  margin: 2rem 0;

  @media only screen and (min-width: 768px) {
    flex-flow: row;
  }
`;

export const Option = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;

  flex: 1 1 0px;

  font-family: ${({ theme }) => theme.fontBody};

  padding: 0.5rem 0;

  text-align: center;

  &.selected {
    background-color: ${({ theme }) => theme.primary500};
    color: black;
    cursor: default;
  }

  &:not(:first-child) {
    border-top: 2px solid black;
    border-left: none;
  }

  &:not(.selected):hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media only screen and (min-width: 768px) {
    &:not(:first-child) {
      border-top: none;
      border-left: 2px solid black;
    }
  }
`;
